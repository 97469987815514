import React from 'react'
import './index.css'
import Navbar from './components/Header'
import { InicioHeader } from './components/Inicio'

export const AppHome = () => {
  return (
    <div className='App'>
      <Navbar />
      <InicioHeader />
    </div>
  )
}
