import React from 'react';
import ReactDOM from 'react-dom/client';
import "./index.css";
import "./App.css";
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { AppHome } from './App';
import reportWebVitals from './reportWebVitals';
import { Error404 } from './pages/404';
import { AppAnuncios } from './pages/Anuncios';
import { FreeServers } from './anuncios/FreeServers';


const router = createBrowserRouter([
  { path: "/", element: <AppHome /> },
  { path: "/anuncios", element: <AppAnuncios /> },
  { path: "/anuncios/FreeServers", element: <FreeServers /> },


  { path: "", element: <Error404 /> },
  { path: "*", element: <Error404 /> },
  { element: <Error404 /> },

])



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

reportWebVitals();
