import React from 'react'

import './Anuncios.css'
import Navbar from '../components/Header'
import { useNavigate } from 'react-router-dom'

export const AppAnuncios = () => {

  const nav = useNavigate()
  return (
    <>
      <Navbar />
      <div className="Anuncios">
        <div className="texto">
          <h1>ANUNCIOS</h1>
          <h4>CACTUS NODES</h4>
          <div className="cajas">

            <button
            onClick={()=>(nav("/anuncios/FreeServers"))}
            className="caja"
            >
              <p>
                <span className='Titulo'>Servidores Gratuitos</span>
                <br />
                <span className='Descripcion'>Anuncio sobre los servidores gratuitos</span>
                <i class="fa-solid fa-arrow-right"></i>
              </p>
            </button>
            
          </div>
        </div>
      </div>
    </>
  )
}
