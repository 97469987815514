import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import LogoCartel from '../images/Logo.png'
import './components.css'

const Navbar = () => {

    const sitio = useLocation()
    const navigate = useNavigate()
    console.log(sitio.pathname)

    let b1, b2, b3, b4, b5;
    if (sitio.pathname == "/") {
        b1 = 'normal'

    }
    if (sitio.pathname == "/anuncios") {
        b2 = 'normal'
    }
    return (
        <div className="navbar">
            <div className="logo">
                <img src={LogoCartel} alt="Logo" />
                <a href="">CACTUS NODES</a>
            </div>

            <div className="buttons">
                <button onClick={() => (navigate("/"))}><a className="button" id={b1}>Inicio</a></button>
                <button onClick={() => (navigate("/anuncios"))}><a className="button" id={b2}>Ver Anuncios</a></button>
                <button><a className="button" id={b3} href="#">Página Oficial</a></button>
            </div>
        </div>
    );
};

export default Navbar;