import React from 'react'
import UsuarioIMG from './Usuarios/Luc4s.png'
import './css/AnunciosGobal.css'
import Navbar from '../components/Header'
import { useNavigate } from 'react-router-dom'

export const FreeServers = () => {

    const nav = useNavigate()
    return (
        <>
            <Navbar />
            <div className="Anuncios">
                <h1>Servidores Gratuitos</h1>
                <div className="Usuario">
                    <a className='EscritoPor'>Escrito por:</a>

                    <div className="Usuarios">
                        <img src={UsuarioIMG} alt="" />
                        <p>
                            <span className='nombre'>Luc4s</span> <br />
                            <span className='role'>Técnico de Cactus Nodes</span></p>
                    </div>
                </div>
                <div className="AnuncioContent">
                    <span className='x18'>Buenas usuarios de CactusNodes, estos son los nuevos requisitos para poder conseguir un servidor gratuito :</span>
                    <br />
                    <br />
                    <ul className='o80'>
                        <li>El bot debe ser un bot público y debe estar en más de 20 servidores</li>
                        <li>El bot debe tener un apartado donde aparezca que esta hosteado en CactusNodes</li>
                        <li>Tener una cuenta creada en nuestro panel  -  <a className='SpanVerde1' href="https://panel.cactusnodes.xyz">panel.cactusnodes.xyz</a></li>
                    </ul>
                    <br />
                    <br />
                    <br />
                    En caso de cumplir estos requisitos abre un ticket en nuestro <a className='SpanVerde' href="">servidor de discord</a>. 
                    <br />
                    <br />
                    En el ticket debera de proporcionarnos la ID de su bot de discord y una captura de pantalla donde aparezca que 
                    el bot esta hosteado en Cactus Nodes, el equipo de Cactus Nodes revisará su solicitud y en caso de cumplir todos los requisitos se les será otorgado el host para su bot de discord de forma gratuita.
                    <br />
                    <span className='o60' >- Cabe aclarar que si el bot esta en multiservidores o es un bot raider no podremos otorfarles el host</span>


                    <br />
                    <br />
                    <button onClick={() => (nav("/anuncios"))}>
                        VOLVER
                        <i class="fa-solid fa-arrow-right"></i>
                    </button>
                </div>

            </div>
        </>
    )
}
