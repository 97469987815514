import React from 'react'
import Logo from '../images/Logo.png'
import { useNavigate } from 'react-router-dom'

export const InicioHeader = () => {

    const nav = useNavigate()
    return (
        <div className='InicioHeader'>
            <img src={Logo} alt="Logo" />
            <div className='text'>
                <h1>Cactus Nodes</h1>
                <a>Obtén servidores de alto rendimiento a bajo coste para impulsar tu proyecto</a>
                <br />
                <button onClick={()=>(nav("/anuncios"))} className='b1'><a>Ver Anuncios</a></button>
                <button className='b2'><a>Servidor de Discord</a></button>
            </div>
        </div>
    )
}
